export const RouteNames = {
  MAIN: "/",
  HOME: "/inicio",
  ABOUT_US: "/nosotros",
  BLOG: "/blog",
  BLOG_POST: "/blog/articulo/",
  LINKEDIN: "https://www.linkedin.com/showcase/ripleytech-/",
  BLOG_AUTHOR: "/blog/autor/",
  VIDEOS: "/Videos",
  WELCOME: "/welcome",
};

export const OPPORTUNITIES_ROUTE = "/oportunidades";
