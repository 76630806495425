import React from "react";
import LogoFooter from "./components/logoFooter";
import SocialNetworkButtons from "./components/SocialNetworksButtons";
import TextoFooter from "./components/textFooter";
import { Grid, makeStyles } from "@material-ui/core";

export function FooterMain() {
  const useStyles = makeStyles({
    footerContainer: {
      backgroundColor: "#000000",
      padding: 55,
      color: "white",
      alignItems: "center",
    },
  });

  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.footerContainer}>
      <Grid item xs={12} md={4} lg={4}>
        <LogoFooter />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextoFooter />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <SocialNetworkButtons />
      </Grid>
    </Grid>
  );
}
