import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { TagBox } from "../UI/TagBox";

const useStyles = makeStyles({
  postCategoriesContainer: {
    margin: "0.5rem 0rem 0rem 0rem",
  },
});

export const PostSummaryCategories = ({ postCategories }) => {
  const classes = useStyles();
  const { selectedTags } = useSelector((state) => state.BlogTags);
  const categories = postCategories.map((category) => {
    return (
      <TagBox
        key={category.id}
        name={category.name}
        clickActive={true}
        active={selectedTags.includes(category.name)}
      />
    );
  });

  return (
    <Grid
      item
      container
      alignContent="center"
      className={classes.postCategoriesContainer}
    >
      {categories}
    </Grid>
  );
};
