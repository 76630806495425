import { Grid, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContentTypes,
  selectContentType,
} from "../../actions/ContentTypeActions";
import { setPostsPage } from "../../actions/PostActions";
import { NormalChip } from "./NormalChip";
import { showAllOption } from "../../reduce/ContentTypeReducer";

const styles = makeStyles({
  categorySection: {
    marginTop: "2rem",
  },
  categoryChip: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "0.3rem",
    marginBottom: "40px",
  },
});

export default function ContentChips() {
  const classes = styles();
  const dispatch = useDispatch();
  const { contentTypes, selectedContent, loading } = useSelector(
    (state) => state.ContentTypes
  );
  useEffect(() => {
    let mounted = true;
    if (mounted && loading) {
      dispatch(getContentTypes());
    }
    return () => {
      mounted = false;
    };
  }, [dispatch, loading]);

  let contentTypesChips = null;

  contentTypesChips = contentTypes.map((content) => {
    return (
      <Grid item key={content.id} className={classes.categoryChip}>
        <NormalChip
          id={content.name}
          label={content.name}
          selected={checkIfIsSelected(content.id)}
          onClick={() => {
            dispatch(selectContentType(content.id, selectedContent));
            dispatch(setPostsPage(1));
          }}
        />
      </Grid>
    );
  });

  return (
    <Fragment>
      <Grid
        container
        item
        className={classes.categorySection}
        justify="flex-start"
      >
        {contentTypesChips}
      </Grid>
    </Fragment>
  );

  function checkIfIsSelected(id) {
    if (id === showAllOption.id && selectedContent.length === 0) {
      return true;
    } else {
      return selectedContent.includes(id);
    }
  }
}
