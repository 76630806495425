import React from "react";
import Grid from "@material-ui/core/Grid";
import { ReproductorVideo } from "../components/VideoView/ReproductorVideo";
import { SuggestedVideo } from "../components/VideoView/SuggestedVideo";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { DescriptionVideo } from "../components/VideoView/DescriptionVideo";
import { TeamsVideo } from "./TeamsVideo";
import Footer from "../../UI/footer/Footer";

export const VideoView = (props) => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));
  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ padding: "2rem 0rem 0rem 0rem" }}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          item
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
        >
          <ReproductorVideo
            url={props.url}
            title={props.Title}
            author={props.autore}
            date={props.createdAt}
          />
          <DescriptionVideo Description={props.Description} />
        </Grid>
        <Grid
          container
          justify={match ? "flex-start" : "center"}
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <SuggestedVideo {...props} />
          <Grid container style={{ width: "95%" }}>
            <TeamsVideo side={match} sideMobil={true} />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};
