import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import RrssListItem from "./rrssListItem";

const RrssList = () => {

    const [socialNetwork, setSocialNetwork] = useState([]);

    useEffect( () => {
        let mounted = true;
        const getData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/rrss-navbars`)
                const data = await res.data;
                (mounted) && setSocialNetwork(data);
            } catch (err) {
                console.log(err)
            }
        };
        getData()
        return () => {
            mounted = false;
        }
    }, []);

    return (
        <>
            {
                socialNetwork.map(item => (
                    <Grid item
                        key={item._id}
                        xs={4} lg={4}
                    >
                        <RrssListItem {...item} />
                    </Grid>
                ))
            }
        </>
    )
}

export default RrssList