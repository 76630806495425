import React, { useEffect, useState } from "react";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { PreviewVideo } from "../ColumnCategoryView/PreviewVideo";
const Styles = makeStyles({
  boxSuggested: {
    border: "1px solid #BCBCBC",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "88%",
  },
  textSuggested: {
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Open Sans",
    color: "#464646",
    width: "83%",
    paddingTop: "1rem",
  },
});
export const SuggestedVideo = ({ Title }) => {
  const [VideoSuggested, setVideoSuggested] = useState([]);
  const { videos } = useSelector((state) => state.Video);
  const classes = Styles();
  useEffect(() => {
    let mounted = true;
    const getSuggestVideo = () => {
      return videos
        .filter((clip) => clip.Title !== Title)
        .map((clip) => {
          let sep = Title.split(" ");
          let array = sep.map((key) => clip.Title.split(" ").includes(key));
          return {
            Suggested:
              array.filter((bool) => bool === true).length / sep.length,
            clip: clip,
          };
        })
        .sort((a, b) => b.Suggested - a.Suggested)
        .slice(0, 2);
    };
    if (mounted) {
      setVideoSuggested(getSuggestVideo());
    }

    return () => {
      mounted = false;
    };
  }, [videos, Title]);
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.boxSuggested}
      >
        <Typography variant="h4" align="left" className={classes.textSuggested}>
          Siguiente video
        </Typography>
        <Divider
          style={{
            width: "90%",
            background: "#BCBCBC",
            margin: "1rem 0rem 1rem 0rem",
          }}
        />
        {VideoSuggested.map((clip) => {
          return (
            <Grid container key={clip.clip._id}>
              <PreviewVideo {...clip.clip} suggested={true} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
