import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { RouteNames } from "../../../routers/RouteNames";
import { Author } from "../UI/Author";

import { PostSummaryCategories } from "./PostSummaryCategories";
import { PostSummaryDescription } from "./PostSummaryDescription";
import { PostSummaryImage } from "./PostSummaryImage";
import { PostSummaryTitle } from "./PostSummaryTitle";

const Styles = makeStyles({
  postContainer: {
    padding: "0rem 1.5rem 0rem 1.5rem",
    marginBottom: "2rem",
  },
  textLink: {
    color: "inherit",
    textDecoration: "inherit",
  },
});

export const PostSummary = (props) => {
  const classes = Styles();

  return (
    <Grid
      container
      item
      data-cy="post-item"
      xl={6}
      lg={6}
      md={6}
      sm={6}
      xs={12}
      className={classes.postContainer}
    >
      <Link
        to={`${RouteNames.BLOG_POST}${props.art.palabraClaveUrl}?q=${props.art._id}`}
        className={classes.textLink}
      >
        <PostSummaryImage url={props.art.Portada.url} />
        <PostSummaryTitle text={props.art.Title} />
        <PostSummaryDescription text={props.art.Descripcion} />
      </Link>
      <PostSummaryCategories postCategories={props.art.blog_tags} />
      <Author
        url={props.art.autores[0].iconoAutor.url}
        Nombre={props.art.autores[0].Nombre}
        id={props.art.autores[0]._id}
        Cuerpo={props.art.Cuerpo}
        createdAt={
          props.art.fecha_publicacion
            ? props.art.fecha_publicacion
            : props.art.createdAt
        }
      />
    </Grid>
  );
};
