const { default: axios } = require("axios");
const { TypeVideos } = require("../types/TypeVideos");

const GetDataVideo = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_PUBLIC_URL}/videos`).then((res) => {
      dispatch(ActionAddVideos(res.data));
    });
    axios(`${process.env.REACT_APP_PUBLIC_URL}/video-destacado`).then((res) => {
      dispatch(ActionAddOutstandingVideos(res.data));
    });
  };
};

const ActionAddVideos = (data) => {
  return {
    type: TypeVideos.AddVideos,
    payload: data,
  };
};

const ActionAddOutstandingVideos = (data) => {
  return {
    type: TypeVideos.OutstandingVideos,
    payload: data,
  };
};

export { GetDataVideo, ActionAddVideos, ActionAddOutstandingVideos };
