import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Loading } from "../loading/Loading";
import axios from "axios";
import { useSelector } from "react-redux";

export const Welcome = () => {
  const [Url, setUrl] = useState({ loading: true });
  const { loadingFeatureFlags } = useSelector((state) => state.FeatureFlags);
  useEffect(() => {
    let mounted = true;
    const getUrl = async () => {
      try {
        const resp = await axios(
          `${process.env.REACT_APP_PUBLIC_URL}/url-bienvenida`
        );
        if (mounted) {
          setUrl(resp.data.url);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUrl();
    return () => {
      mounted = false;
    };
  }, []);
  if (Url.loading || loadingFeatureFlags) {
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        style={{ height: "80vh" }}
      >
        <Loading />
      </Grid>
    );
  } else {
    window.location.href = Url;
    return null;
  }
};
