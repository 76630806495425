import { GET_QUESTIONS, GET_QUESTIONS_ERROR } from "./QuestionsTypes";

const initState = {
  questions: [],
  loadingQuestions: true,
};

export const QuestionsReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        loadingQuestions: false,
      };
    case GET_QUESTIONS_ERROR:
      return {
        ...state,
        loadingQuestions: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
