import {
  GET_REFERENCES,
  GET_REFERENCES_ERROR,
  SET_REFERENCE,
} from "./ReferenceTypes";

const initState = {
  references: [],
  loadingReferences: true,
  direction: "left",
  selectedReference: 0,
};
export const ReferenceReducer = (state = initState, action = "") => {
  switch (action.type) {
    case GET_REFERENCES:
      return {
        ...state,
        references: [...action.payload],
        loadingReferences: false,
      };
    case GET_REFERENCES_ERROR:
      return {
        ...state,
        loadingReferences: false,
        error: action.payload,
      };
    case SET_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.index,
        direction: action.payload.direction,
      };
    default:
      return state;
  }
};
