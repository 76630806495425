import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { Author } from "../../../Blog/components/UI/Author";

const Styles = makeStyles({
  textTitleClip: {
    fontWeight: "bold",
    fontFamily: "barlow",
  },
});
export const PreviewVideo = (props) => {
  const classes = Styles();
  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          padding: "1rem 1.5rem 1rem 1.5rem",
          width: "100%",
        }}
        item
        xs={12}
        sm={12}
        md={props.suggested ? 12 : 4}
        lg={props.suggested ? 12 : 4}
        xl={props.suggested ? 12 : 4}
      >
        <Link
          to={`/videos/${props.Title}`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <img
            alt="imagenPortadaVideo"
            src={`${props.Portada.url}`}
            style={{ width: "100%" }}
          />
          <Typography
            variant="body1"
            className={classes.textTitleClip}
            align="left"
          >
            {props.Title}
          </Typography>
        </Link>
        <Author {...props.autore} video={true} createdAt={props.createdAt} />
      </Grid>
    </>
  );
};
