import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { AvatarAuthor } from "../components/ProfileAuthorView/AvatarAuthor";
import { ArticlesAuthor } from "../components/ProfileAuthorView/ArticlesAuthor";
import ContentChips from "../components/ContentChipsView/ContentChips";
import Footer from "../../UI/footer/Footer";
import { BlogBreadcrumbs } from "../components/UI/BlogBreadcrumbs";
import { useSelector } from "react-redux";
import { Loading } from "../../UI/loading/Loading";

export const ProfileAuthorContent = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { idAuthor } = useParams();
  const [DataAuthor, setDataAuthor] = useState({ loading: true });
  const { authorCategoryChipsDisabled } = useSelector(
    (state) => state.FeatureFlags
  );
  useEffect(() => {
    let mounted = true;
    const getDataAuthor = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/autores/${idAuthor}`
        );
        if (mounted) {
          setDataAuthor(resp.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDataAuthor();
    return () => {
      mounted = false;
    };
  }, [idAuthor]);
  let categoryChips = null;
  if (authorCategoryChipsDisabled !== true) {
    categoryChips = <ContentChips />;
  }

  if (DataAuthor.loading) return <Loading />;
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        style={{
          padding: !matches ? "0rem 5rem 0rem 5rem" : "1rem",
        }}
      >
        <BlogBreadcrumbs title={"Autores"} />
        <Typography variant="h3" align="left">
          {DataAuthor.Nombre}
        </Typography>
      </Grid>
      <Divider />

      <Grid
        container
        direction="row"
        justify="center"
        style={{
          padding: !matches ? "0rem 5rem 0rem 5rem" : null,
        }}
      >
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          xs={12}
          sm={8}
          md={4}
          lg={4}
          xl={4}
        >
          <AvatarAuthor
            icono={DataAuthor.iconoAutor}
            nombre={DataAuthor.Nombre}
            cargo={DataAuthor.Cargo}
            email={DataAuthor.Email}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid
            container
            style={{
              padding: "0rem 0rem 1rem 0rem",
            }}
          >
            {categoryChips}
          </Grid>
          <ArticlesAuthor author={DataAuthor} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};
