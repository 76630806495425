import React from "react";
import { SelectedChip } from "./SelectedChip";
import { UnselectedChip } from "./UnselectedChip";

export function NormalChip(props) {
  if (props.selected) {
    return (
      <SelectedChip id={props.id} label={props.label} onClick={props.onClick} />
    );
  } else {
    return (
      <UnselectedChip
        id={props.id}
        label={props.label}
        onClick={props.onClick}
      />
    );
  }
}
