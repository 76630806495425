import { TagsTypes } from "../Types/TagsTypes";

export const initialState = {
  tags: [],
  selectedTags: [],
  loadingTags: true,
  tagsBoxLimit: 0,
};

const keepUniqueValues = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const TagsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case TagsTypes.GET_TAGS:
      return {
        ...state,
        tags: action.payload,
        loadingTags: false,
      };
    case TagsTypes.GET_ERROR:
      return {
        ...state,
        loadingTags: false,
        error: action.payload,
      };
    case TagsTypes.ADD_TAG_FILTER:
      return {
        ...state,
        selectedTags: [...state.selectedTags, action.payload].filter(
          keepUniqueValues
        ),
      };
    case TagsTypes.REMOVE_TAG_FILTER:
      let arr = state.selectedTags;
      let index = arr.indexOf(action.payload);
      arr.splice(index, 1);
      return {
        ...state,
        selectedTags: arr,
      };
    case TagsTypes.RESET_FILTER:
      return {
        ...state,
        selectedTags: [],
      };
    case TagsTypes.GET_LIMIT:
      return {
        ...state,
        tagsBoxLimit: action.payload.limitNumber,
      };
    case TagsTypes.GET_LIMIT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
