import { typesFeatureFlags } from "../types/typesFeatureFlags";

const initialState = {
  loadingFeatureFlags: true,
};

export const FeatureFlagsReducer = (state = initialState, action = "") => {
  switch (action.type) {
    case typesFeatureFlags.ADD_FEATURE_FLAGS:
      return { ...state, ...action.payload, loadingFeatureFlags: false };
    case typesFeatureFlags.ERROR_GET_FEATURE_FLAGS:
      return { ...state, error: action.payload, loadingFeatureFlags: false };
    default:
      return state;
  }
};
