import ContentTypeTypes from "../Types/ContentTypeTypes";

const initState = {
  contentTypes: [],
  loading: true,
  selectedContent: [],
};
export const showAllOption = { name: "Todo", id: "showAll" };

const ContentTypeReducer = (state = initState, action = "") => {
  switch (action.type) {
    case ContentTypeTypes.GET_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: [showAllOption, ...action.payload],
        loading: false,
      };
    case ContentTypeTypes.ERROR_CONTENT_TYPES:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ContentTypeTypes.SELECT_CONTENT_TYPE:
      const { selectedId } = action.payload;
      return {
        ...state,
        selectedContent: [
          ...state.selectedContent.filter(
            (contentId) => contentId !== selectedId
          ),
          selectedId,
        ],
      };
    case ContentTypeTypes.DESELECT_CONTENT_TYPE:
      const { deselectedId } = action.payload;
      return {
        ...state,
        selectedContent: state.selectedContent.filter(
          (contentId) => contentId !== deselectedId
        ),
      };
    case ContentTypeTypes.RESET_CONTENT_TYPES:
      return {
        ...state,
        selectedContent: [],
      };
    default:
      return state;
  }
};

export default ContentTypeReducer;
