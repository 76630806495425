import PodcastPlaylistTypes from "../Types/PodcastPlaylistTypes";

const initState = {
  playlistId: "",
  loadingPodcastPlaylist: true,
};
export const PodcastPlaylistReducer = (state = initState, action = "") => {
  switch (action.type) {
    case PodcastPlaylistTypes.GET_PODCAST_PLAYLIST:
      return {
        ...state,
        playlistId: action.payload.spotifyCode,
        loadingPodcastPlaylist: false,
      };
    case PodcastPlaylistTypes.ERROR_POSTS:
      return {
        ...state,
        loadingPodcastPlaylist: false,
        error: false,
      };
    default:
      return state;
  }
};
