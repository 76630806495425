import { TypeVideos } from "../types/TypeVideos";

export const VideosReducer = (
  state = { videos: [], OutstandingVideos: [] },
  action = ""
) => {
  switch (action.type) {
    case TypeVideos.AddVideos:
      return {
        ...state,
        videos: action.payload,
        popular: action.payload.filter((clip) => clip.Popular === true),
      };
    case TypeVideos.OutstandingVideos:
      return {
        ...state,
        OutstandingVideos: action.payload.video,
      };

    default:
      return state;
  }
};
