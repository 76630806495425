import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { Avatar, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  textTitle: {
    fontWeight: "bold",
    fontFamily: "barlow",
    color: "#464646",
    textOverflow: "ellipsis",
  },
  textAuthor: {
    fontFamily: "barlow",
    color: "#EB3C90",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  textTiempo: {
    color: "#464646",
    fontSize: "0.8em",
    textAlign: "left",
    padding: " 0rem 0rem 0rem 0rem",
  },
}));
export const AuthorVideo = ({ Title, author, date }) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const [Author, setAuthor] = useState({ loading: true });
  const classes = useStyles();
  useEffect(() => {
    let mounted = true;
    const GetAuthor = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/autores/${author}`
        );
        setAuthor(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (mounted) {
      !author.Nombre ? GetAuthor() : setAuthor(author);
    }
    return () => {
      mounted = false;
    };
  }, [author]);
  if (Author.loading) {
    return <></>;
  }
  return (
    <>
      <Grid container style={{ width: "83%" }}>
        <Grid
          container
          justify="center"
          alignContent="center"
          item
          xs={2}
          sm={2}
          md={1}
          lg={1}
        >
          <Avatar className={classes.large} src={`${Author.iconoAutor.url}`} />
        </Grid>
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          xs={10}
          sm={10}
          md={11}
          lg={11}
          style={{ padding: "0rem 0rem 0rem 1rem" }}
        >
          <Typography variant="h4" className={classes.textTitle}>
            {Title}
          </Typography>
          <Typography variant="body1" className={classes.textAuthor}>
            {Author.Nombre}
          </Typography>
          <Typography variant="body1" className={classes.textTiempo}>
            {new Date(date.replace(/-/g, "/")).toLocaleDateString(
              "es-ES",
              options
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
