import React, { Fragment } from "react";

import FooterIcon from "./components/FooterIcon";
import { FooterMain } from "./FooterMain";

export const Footer = (props) => {
  return (
    <Fragment>
      <FooterIcon backgroundColor={props.backgroundColor} />
      <FooterMain />
    </Fragment>
  );
};

export default Footer;
