import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  textTile: {
    textAlign: "left",
    fontWeight: "bold",
    padding: "0.5rem 0rem 0.5rem 0rem",
    cursor: "pointer",
    fontFamily: "Barlow",
    color: "#464646",
  },
});

export const PostSummaryTitle = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      align="left"
      className={classes.textTile}
      style={{
        width: "100%",
      }}
    >
      {text}
    </Typography>
  );
};
