import { ERROR_GET_WELCOME_DATA, GET_WELCOME_DATA } from "./WelcomeTypes";

const initState = {
  welcomeSiteData: {},
  loadingWelcome: true,
};
export const WelcomeReducer = (state = initState, action = "") => {
  switch (action.type) {
    case GET_WELCOME_DATA:
      return {
        ...state,
        welcomeSiteData: action.payload.data,
        loadingWelcome: false,
      };
    case ERROR_GET_WELCOME_DATA:
      return {
        ...state,
        loadingWelcome: false,
      };
    default:
      return state;
  }
};
