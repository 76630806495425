import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";

import ReactPlayer from "react-player/youtube";
import { AuthorVideo } from "../OutstadingVideoView/AuthorVideo";

export const ReproductorVideo = ({ url, title, author, date }) => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));
  return (
    <>
      <ReactPlayer
        width={match ? "88%" : "98%"}
        height={match ? "645px" : "32vh"}
        controls={true}
        url={`https://www.youtube.com/watch?v=${url}`}
      />

      <AuthorVideo Title={title} author={author} date={date} />
    </>
  );
};
