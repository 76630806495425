import axios from "axios";
import { TagsTypes } from "../Types/TagsTypes";

export const getTags = () => async (dispatch) => {
  try {
    const { data } = await axios(
      `${process.env.REACT_APP_PUBLIC_URL}/blog-categories`
    );

    dispatch({
      type: TagsTypes.GET_TAGS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TagsTypes.GET_ERROR,
      payload: error,
    });
  }
};

export const getTagsBoxLimit = () => async (dispatch) => {
  try {
    const { data } = await axios(
      `${process.env.REACT_APP_PUBLIC_URL}/blog-tags-limit`
    );

    dispatch({
      type: TagsTypes.GET_LIMIT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TagsTypes.GET_LIMIT_ERROR,
      payload: error,
    });
  }
};

export const actionAddFilter = (idCat) => {
  return {
    type: TagsTypes.ADD_TAG_FILTER,
    payload: idCat,
  };
};

export const actionDeleteFilter = (idCat) => {
  return {
    type: TagsTypes.REMOVE_TAG_FILTER,
    payload: idCat,
  };
};

export const resetFilter = () => (dispatch) => {
  dispatch({
    type: TagsTypes.RESET_FILTER,
  });
};
