import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import loadGif from "../../assets/img/loading.gif";

export const Loading = () => {
  return (
    <>
      <Box style={{ background: "white", height: "100vh" }}>
        <Grid
          container
          spacing={1}
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          style={{ height: "100%", color: "#666666" }}
        >
          <img src={loadGif} alt="loading" width="250px" />
          <Typography variant="h4">...despegando...</Typography>
        </Grid>
      </Box>
    </>
  );
};
