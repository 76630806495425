import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "../UI/navbar/Navbar";
import { OPPORTUNITIES_ROUTE, RouteNames } from "./RouteNames";
import { ProfileAuthorContent } from "../Blog/views/ProfileAuthorContent";
import { Loading } from "../UI/loading/Loading";
import { Welcome } from "../UI/welcome/Welcome";
import { useSelector } from "react-redux";
import { Video } from "../Video/Video";
import { VideoSelected } from "../Video/VideoSelected";

// Lazy views imports
const AboutUs = lazy(() => import("../AboutUs/AboutUs"));
const Home = lazy(() => import("../Home/Home"));
const Blog = lazy(() => import("../Blog/Blog"));
const ShowBlog = lazy(() => import("../Blog/views/ShowBlog"));
const WelcomeSite = lazy(() => import("../Welcome/Welcome"));
const Opportunities = lazy(() => import("../Opportunities/Opportunities"));

const AppRouter = () => {
  const { bienvenida, opportunities } = useSelector(
    (state) => state.FeatureFlags
  );

  return (
    <>
      <Router>
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={RouteNames.MAIN} component={Home} />
            <Route exact path={RouteNames.HOME} component={Home} />
            <Route exact path={RouteNames.ABOUT_US} component={AboutUs} />
            <Route exact path={RouteNames.BLOG} component={Blog} />
            <Route
              exact
              path={`${RouteNames.BLOG_POST}:keySecret`}
              component={ShowBlog}
            />
            <Route
              exact
              path="/blog/autor/:idAuthor"
              component={ProfileAuthorContent}
            />
            {bienvenida ? (
              <Route exact path="/bienvenida" component={WelcomeSite} />
            ) : (
              <Route exact path="/bienvenida" component={Welcome} />
            )}
            <Route path="/videos" exact component={Video} />
            <Route path="/videos/:titulo" exact component={VideoSelected} />
            {opportunities && (
              <Route
                path={OPPORTUNITIES_ROUTE}
                exact
                component={Opportunities}
              />
            )}
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default AppRouter;
