import axios from "axios";
import { typesFeatureFlags } from "../types/typesFeatureFlags";

const getFeatureFlags = () => async (dispatch) => {
  let savedDate = null;
  if (localStorage.getItem("FeatureFlags") !== null) {
    savedDate = new Date(
      JSON.parse(localStorage.getItem("FeatureFlags")).create
    );
  }
  const nowDate = new Date();

  try {
    if ((nowDate - savedDate) / 1000 / 60 / 60 < 24) {
      return dispatch(
        addFeatureFlags(JSON.parse(localStorage.getItem("FeatureFlags")).data)
      );
    } else {
      const { data: featureFlagsData } = await axios(
        `${process.env.REACT_APP_PUBLIC_URL}/feature-flags`
      );
      localStorage.setItem(
        "FeatureFlags",
        JSON.stringify({
          data: featureFlagsData,
          create: new Date(),
        })
      );
      return dispatch(addFeatureFlags(featureFlagsData));
    }
  } catch (error) {
    console.error(error);
    return dispatch({
      type: typesFeatureFlags.ERROR_GET_FEATURE_FLAGS,
      payload: error,
    });
  }
};

const addFeatureFlags = (data) => {
  let objectFlags = {};
  data.forEach((flag) => {
    objectFlags = {
      ...objectFlags,
      [flag.name]: flag.enabled,
    };
  });
  return {
    type: typesFeatureFlags.ADD_FEATURE_FLAGS,
    payload: objectFlags,
  };
};

export { getFeatureFlags, addFeatureFlags };
