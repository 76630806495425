import {
  GET_OPPORTUNITIES_CONFIG,
  GET_OPPORTUNITIES_ERROR,
} from "./OpportunitiesTypes";

const initState = {
  config: {},
  loadingConfig: true,
};

export const OpportunitiesReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_OPPORTUNITIES_CONFIG:
      return {
        ...state,
        config: action.payload,
        loadingConfig: false,
      };
    case GET_OPPORTUNITIES_ERROR:
      return {
        ...state,
        loadingConfig: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
