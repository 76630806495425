import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetDataVideo } from "./action/ActionsVideos";
import { VideoView } from "./views/VideoView";

export const VideoSelected = () => {
  const { titulo } = useParams();
  const { videos } = useSelector((state) => state.Video);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted && videos.length === 0) {
      dispatch(GetDataVideo());
    }
    return () => {
      mounted = false;
    };
  }, [videos.length, dispatch]);
  if (videos.length === 0) {
    return <>CARGANDO...</>;
  }
  return (
    <>
      <VideoView {...videos.filter((clip) => clip.Title === titulo)[0]} />
    </>
  );
};
