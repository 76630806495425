import PostTypes from "../Types/PostTypes";

const initState = {
  allPosts: [],
  totalPages: 1,
  loadingPosts: true,
  postsPage: 1,
  indexDestacado: -1,
};
export const PostsReducer = (state = initState, action = "") => {
  switch (action.type) {
    case PostTypes.GET_POSTS:
      return {
        ...state,
        allPosts: [...action.payload.data],
        indexDestacado: action.payload.indexDestacado,
        loadingPosts: false,
      };
    case PostTypes.ERROR_POSTS:
      return {
        ...state,
        loadingPosts: false,
      };
    case PostTypes.SET_PAGE:
      return {
        ...state,
        postsPage: action.payload.postsPage,
      };
    case PostTypes.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload.totalPages,
      };
    default:
      return state;
  }
};
