import React from 'react';

const RrssListItem = ({
    Url: urlLink,
    Logo
}) => {

    const {alternativeText, url: urlImage} = Logo;

    return (
        <>
            <a href={urlLink} target="_blank" rel="noopener noreferrer">
                <img
                    src={urlImage}
                    alt={alternativeText}
                />
            </a>
        </>
    )
}

export default RrssListItem
