import React from "react";
import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo-menu.svg";
import RrssList from "./rrssList";
import { OPPORTUNITIES_ROUTE, RouteNames } from "../../../routers/RouteNames";
import resetBlogState from "../../../Blog/functions/resetBlogState";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "white",
  },
  logo: {
    padding: "1.1rem 3rem 1rem 3rem",
  },
  tabs: {
    height: "5rem",
    justifyContent: "flex-end",
  },
  tab: {
    color: "black",
    fontWeight: "bold",
    textAlign: "end",
    height: "5rem",
  },
});

const getSelectedTab = (path) => {
  switch (true) {
    case path === "/":
      return RouteNames.HOME;
    case path.includes(RouteNames.BLOG):
      return RouteNames.BLOG;
    case path.includes(OPPORTUNITIES_ROUTE):
      return OPPORTUNITIES_ROUTE;
    case path !== "/":
      return path;
    default:
      break;
  }
};

const NavbarDesktop = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { blog, videos, opportunities } = useSelector(
    (state) => state.FeatureFlags
  );

  return (
    <>
      <Grid container item lg={5}>
        <Link to={RouteNames.MAIN}>
          <img
            src={Logo}
            alt="Logo Ripleytech"
            className={classes.logo}
            style={{
              position: "absolute",
            }}
          />
        </Link>
      </Grid>
      <Grid container item lg={6} justify="flex-end">
        <Tabs
          scrollButtons="off"
          className={classes.tabs}
          value={getSelectedTab(history.location.pathname)}
        >
          {opportunities && (
            <Tab
              className={classes.tab}
              value={OPPORTUNITIES_ROUTE}
              label="Oportunidades"
              component={Link}
              to={OPPORTUNITIES_ROUTE}
            />
          )}
          {!opportunities && (
            <Tab
              className={classes.tab}
              value={RouteNames.HOME}
              label="Inicio"
              component={Link}
              to={RouteNames.HOME}
            />
          )}

          <Tab
            className={classes.tab}
            value={RouteNames.ABOUT_US}
            label="Nosotros"
            component={Link}
            to={RouteNames.ABOUT_US}
          />
          {blog && (
            <Tab
              className={classes.tab}
              value={RouteNames.BLOG}
              label="Blog"
              component={Link}
              to={RouteNames.BLOG}
              onClick={resetBlogState(dispatch)}
            />
          )}
          {videos && (
            <Tab
              className={classes.tab}
              value={RouteNames.VIDEOS}
              label="Videos"
              component={Link}
              to={RouteNames.VIDEOS}
            />
          )}
        </Tabs>
      </Grid>

      <Grid
        container
        item
        md={2}
        lg={1}
        justify="space-evenly"
        alignItems="center"
      >
        <RrssList />
      </Grid>
    </>
  );
};

export default NavbarDesktop;
