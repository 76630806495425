import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../actions/PostActions";
import { PostSummary } from "../PostsView/PostSummary";
import { PaginationArticleAuthor } from "./PaginationArticleAuthor";

export const ArticlesAuthor = ({ author }) => {
  const [ArtPostSumary, setArtPostSumary] = useState([]);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const { contentTypes, selectedContent } = useSelector(
    (state) => state.ContentTypes
  );
  const { allPosts } = useSelector((state) => state.Posts);
  const [Page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      if (mounted) {
        dispatch(getPosts());
      }
    };
    getData();

    return () => {
      mounted = false;
    };
  }, [dispatch]);
  useEffect(() => {
    let mounted = true;
    let contentTypeSelect = contentTypes.filter((content) =>
      selectedContent.includes(content.id)
    );
    const getArtAuthor = () => {
      if (mounted) {
        setArtPostSumary(
          allPosts
            .filter((post) => post.autores[0]._id === author._id)
            .slice(6 * (Page - 1), 6 * Page)
        );
      }

      if (contentTypeSelect) {
        if (contentTypeSelect.length > 0) {
          setArtPostSumary(
            allPosts
              .filter((post) => post.autores[0]._id === author._id)
              .filter((art) =>
                contentTypeSelect.some(
                  (category) => category.id === art.blog_categories.id
                )
              )
              .slice(6 * (Page - 1), 6 * Page)
          );
        }
      }
    };
    getArtAuthor();
    return () => {
      mounted = false;
    };
  }, [contentTypes, selectedContent, author, allPosts, Page]);
  return (
    <>
      <Grid container direction="row" style={{ width: "100%" }}>
        {ArtPostSumary.map((art) => (
          <PostSummary
            key={art._id}
            matchesMd={matchesMd}
            art={{ ...art, autores: [{ ...author }] }}
            autores={author}
          />
        ))}
      </Grid>
      <PaginationArticleAuthor
        cant={
          Math.ceil(ArtPostSumary.length / 6) === 0
            ? 1
            : Math.ceil(ArtPostSumary.length / 6)
        }
        Page={Page}
        setPage={setPage}
      />
    </>
  );
};
