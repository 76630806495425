import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  postImg: {
    height: "intrinsic",
    width: "100%",
    maxHeight: "24rem",
    cursor: "pointer",
  },
});

export const PostSummaryImage = ({ url }) => {
  const classes = useStyles();
  return (
    <Grid container item justify="center" alignContent="flex-start">
      <img src={url} alt="portada" className={classes.postImg} />
    </Grid>
  );
};
