import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../assets/css/footer.css";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

export const FooterIcon = (props) => {
  const [footerIcon, setFooterIcon] = useState([]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/footer-icon`
        );
        const data = await res.data;
        if (mounted) {
          setFooterIcon(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    
    getData();
    
    return function cleanup() {
      mounted = false;
    };
  }, []);
  
    const theme = useTheme();
    const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const useStyles = makeStyles({
    iconImage: {
      marginRight: `${matchesMdUp ? '10rem' : '0rem'}`,
    },
    iconContainer: {
      background: `${ props.backgroundColor ? props.backgroundColor : 'white' }`,
      justifyContent: `${matchesMdUp ? 'flex-end' : 'center'}`,
    }
  });
  
  const classes = useStyles();
  
  return (
    <>
      <Grid container className={ classes.iconContainer }>
        <img
          id={footerIcon._id}
          className={ classes.iconImage }
          src={ footerIcon.image ? footerIcon.image.url : ''}
          alt={footerIcon.image ? footerIcon.image.alternativeText : ''}
        />
      </Grid>
    </>
  );
};

export default FooterIcon;
