import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";
const Styles = makeStyles({
  DescriptionText: {
    fontFamily: "Georgia",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "32px",
    color: "#464646",
    padding: "2rem 0rem 2rem 0rem",
  },
});
export const DescriptionVideo = ({ Description }) => {
  const classes = Styles();
  return (
    <>
      <Grid container justify="flex-start" style={{ width: "88%" }}>
        <Typography
          variant="body1"
          align="left"
          className={classes.DescriptionText}
        >
          {Description}
        </Typography>
      </Grid>
      <Divider style={{ width: "88%", marginBottom: "1rem" }} />
    </>
  );
};
