import { Chip, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles({
  unselectedChip: {
    color: "#EB3C90",
    backgroundColor: "white",
    padding: "0rem 1rem 0rem 1rem",
    border: "1px solid #EB3C90",
  },
  label: {
    fontFamily: "Barlow",
    fontWeight: "bold",
    fontSize: "1rem",
  },
});

export function UnselectedChip(props) {
  const classes = styles();
  return (
    <Chip
      id={`chip-${props.id}`}
      data-testid="test-chip-unSelect"
      data-cy={`chip-${props.id}`}
      label={props.label}
      variant="outlined"
      classes={{
        label: classes.label,
      }}
      onClick={props.onClick}
      className={classes.unselectedChip}
    />
  );
}
