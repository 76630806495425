import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { TeamProject } from "../components/TeamVideosView/TeamProject";
import axios from "axios";
import { useMediaQuery, useTheme } from "@material-ui/core";

export const TeamsVideo = ({ side, sideMobil }) => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));
  const [Teams, setTeams] = useState([]);
  useEffect(() => {
    let mounted = true;
    const getTeams = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/projects-lists`
        );
        if (mounted) {
          setTeams(resp.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTeams();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <>
      <Grid container justify="center">
        <Grid
          container
          wrap="nowrap"
          direction={side ? "column" : "row"}
          style={
            sideMobil ? { width: "100%" } : { width: match ? "80%" : "90%" }
          }
        >
          {Teams.map((team, i) => {
            return (
              <TeamProject
                side={side}
                sideMobil={sideMobil}
                key={i}
                logo={team.Image}
                largo={Teams.length}
                color={team.color}
                name={team.Title}
              />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
