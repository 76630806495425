import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../assets/css/footer.css";

export const FooterLogo = () => {
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/logo-footer`
        );
        const data = await res.data.logoFooter[0];
        if (mounted) {
          setLogo(data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getData();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <>
      {
        <img
          id={logo._id}
          className="logoFooter"
          src={logo.url}
          alt={logo.Alt}
        />
      }
    </>
  );
};

export default FooterLogo;
