import axios from "axios";
import ContentTypeTypes from "../Types/ContentTypeTypes";
import { showAllOption } from "../reduce/ContentTypeReducer";

export const getContentTypes = () => async (dispatch) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_PUBLIC_URL}/tipo-contenidos`
    );
    dispatch({
      type: ContentTypeTypes.GET_CONTENT_TYPES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ContentTypeTypes.ERROR_CONTENT_TYPES,
      payload: error,
    });
  }
};

export const selectContentType = (id, selectedContentTypes) => (dispatch) => {
  if (id === showAllOption.id) {
    dispatch({
      type: ContentTypeTypes.RESET_CONTENT_TYPES,
    });
  } else if (!selectedContentTypes.includes(id)) {
    dispatch(addContentType(id));
  } else {
    dispatch(removeContentType(id));
  }
};

export const resetContentTypes = () => (dispatch) => {
  dispatch({
    type: ContentTypeTypes.RESET_CONTENT_TYPES,
  });
};

export const removeContentType = (id) => (dispatch) => {
  dispatch({
    type: ContentTypeTypes.DESELECT_CONTENT_TYPE,
    payload: {
      deselectedId: id,
    },
  });
};

export const addContentType = (id) => (dispatch) => {
  dispatch({
    type: ContentTypeTypes.SELECT_CONTENT_TYPE,
    payload: {
      selectedId: id,
    },
  });
};
