import { Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../UI/footer/Footer";
import { Loading } from "../UI/loading/Loading";
import { GetDataVideo } from "./action/ActionsVideos";
import { ColumnCategory } from "./views/ColumnCategory";
import { OutstadingVideo } from "./views/OutstadingVideo";
import { TeamsVideo } from "./views/TeamsVideo";
export const Video = () => {
  const dispatch = useDispatch();
  const [target, settarget] = useState([]);
  const { popular, videos } = useSelector((state) => state.Video);
  const [CategoryVideo, setCategoryVideo] = useState([]);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery().get("target");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        setLoading(false);
      }, 3500);
      if (query) {
        settarget(
          videos.filter((clip) => {
            if (!clip.projects_list) {
              return false;
            }
            if (
              clip.projects_list.Title.trim(" ").toUpperCase() ===
              query.trim(" ").toUpperCase()
            ) {
              return true;
            }
            return false;
          })
        );
      }
    }
    return () => {
      mounted = false;
    };
  }, [query, videos]);
  useEffect(() => {
    const AgroupCategoryVideos = () => {
      let ArrayVideosCategory = [];
      videos.forEach((clip) => {
        let i = ArrayVideosCategory.findIndex(
          (a) => a.NameCategory === clip.blog_category.name
        );
        if (i !== -1) {
          ArrayVideosCategory[i] = {
            ...ArrayVideosCategory[i],
            Videos: [...ArrayVideosCategory[i].Videos, clip],
          };
        } else {
          ArrayVideosCategory = [
            ...ArrayVideosCategory,
            {
              NameCategory: clip.blog_category.name,
              Videos: [clip],
            },
          ];
        }
      });
      setCategoryVideo(ArrayVideosCategory);
    };
    let mounted = true;
    if (mounted) {
      window.scrollTo({ top: 0 });
      AgroupCategoryVideos();
    }
    return () => {
      mounted = false;
    };
  }, [videos]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(GetDataVideo());
    }
    return () => {
      mounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loading) {
      document.body.style.overflow = "hidden";
    }
    if (mounted && !loading) {
      document.body.style.overflow = "scroll";
    }
    return () => {
      mounted = false;
    };
  }, [loading]);

  return (
    <>
      <Box hidden={!loading}>
        <Loading />
      </Box>
      <OutstadingVideo />

      <Grid container justify="center">
        <Divider style={{ width: "80%", margin: "1rem 0rem 1rem 0rem" }} />
      </Grid>

      <TeamsVideo />

      {query ? (
        <>
          <ColumnCategory NameCategory={query} Videos={target} bread={true} />
        </>
      ) : (
        <>
          <ColumnCategory NameCategory="popular" Videos={popular} />

          {CategoryVideo.map((category) => (
            <ColumnCategory key={category.NameCategory} {...category} />
          ))}
        </>
      )}

      <Footer />
    </>
  );
};
