import { TypeGob } from "../Types/TypeGOB";

const init = {
  jobs: [],
  categorias: [],
  loading: true,
};

export const GOBReducer = (state = init, action = "") => {
  switch (action.type) {
    case TypeGob.ADD_JOBS:
      return {
        jobs: action.payload.jobs,
        categorias: action.payload.categorias,
        loading: false,
      };
    case TypeGob.SELECTED_CATEGORY:
      return {
        ...state,
        categorias: state.categorias.map((cat) =>
          cat.name === action.payload.name
            ? { name: cat.name, selected: true }
            : { name: cat.name, selected: false }
        ),
      };
    default:
      return state;
  }
};
