import React from "react";
import { Grid } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
const styles = makeStyles({
  selected: {
    background: "black !important",
    color: "white",
  },
});

export const PaginationArticleAuthor = ({ cant = 1, setPage, Page = 1 }) => {
  const classes = styles();
  return (
    <>
      <Grid
        container
        justify="flex-end"
        alignContent="flex-end"
        style={{ margin: "3rem 1rem 1rem 1rem" }}
      >
        <Pagination
          count={cant}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                selected: classes.selected,
              }}
              {...item}
            />
          )}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => {
            setPage(page);
          }}
          page={Page}
        />
      </Grid>
    </>
  );
};
