import React, { useEffect } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from "@material-ui/core";
import AppRouter from "./routers/AppRouter";
import "./assets/css/App.css";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import { getFeatureFlags } from "./reducerIndex/action/ActionsFeatureFlags";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </React.StrictMode>
  );
};

function AppWrapper() {
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(getFeatureFlags());
    }

    return () => {
      mounted = false;
    };
  }, [dispatch]);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <AppRouter />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
