import { Avatar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import readingTime from "reading-time";
import { RouteNames } from "../../../routers/RouteNames";

const styles = makeStyles({
  textTiempo: {
    color: "#464646",
    fontSize: "0.8em",
    textAlign: "left",
    padding: " 0rem 0rem 0rem 0.5rem",
  },
  nombreAutor: {
    color: "#EB3C90",
    padding: " 0rem 0rem 0rem 0.5rem",
  },
  cajaTextAutorDate: {
    textAlign: "left",
  },
});

export const Author = (props) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const classes = styles();
  return (
    <Link
      to={`${RouteNames.BLOG_AUTHOR}${props.id}`}
      style={{ color: "inherit", textDecoration: "inherit" }}
    >
      <Grid
        container
        direction="row"
        item
        style={{
          margin: "0.5rem 0rem 1rem 0rem",
        }}
      >
        <Grid item>
          <Avatar sizes="large">
            <img
              src={`${props.iconoAutor ? props.iconoAutor.url : props.url}`}
              alt="icono autor"
              width="100%"
            />
          </Avatar>
        </Grid>
        <Grid item className={classes.cajaTextAutorDate}>
          <Typography variant="body2" className={classes.nombreAutor}>
            {props.Nombre}
          </Typography>
          <Typography variant="body2" className={classes.textTiempo}>
            {new Date(props.createdAt.replace(/-/g, "/")).toLocaleDateString(
              "es-CL",
              options
            )}
            {props.video ? "" : " • " + readingTime(props.Cuerpo).text}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};
