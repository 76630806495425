import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { actionAddFilter, actionDeleteFilter } from "../../actions/TagsActions";
import { setPostsPage } from "../../actions/PostActions";

const Styles = makeStyles({
  categoryBox: {
    borderRadius: "0.2rem",
    margin: "0.5rem 0.2rem 0rem 0.2rem",
    background: "#F2F2F2",
    color: "#666666",
  },
  categoryBoxActive: {
    borderRadius: "0.2rem",
    margin: "0.5rem 0.2rem 0rem 0.2rem",
    background: "#EB3C90",
  },
  categoryText: {
    fontSize: "0.8em",
    margin: "0rem 0.5rem 0rem 0.5rem",
    color: "#666",
  },
  categoryTextSelected: {
    color: "white",
  },
});

export const TagBox = ({ name, clickActive = false, active = false }) => {
  const dispatch = useDispatch();
  const classes = Styles();
  const addCategory = (categorieName) => {
    if (active) {
      dispatch(actionDeleteFilter(categorieName));
    } else {
      dispatch(actionAddFilter(categorieName));
    }
    dispatch(setPostsPage(1));
  };
  return (
    <Box
      data-cy="tag-box"
      className={`${classes.categoryBox} ${
        active && classes.categoryBoxActive
      }`}
      onClick={clickActive ? () => addCategory(name) : null}
      style={{ cursor: clickActive ? "pointer" : "auto" }}
    >
      <Typography
        variant="body1"
        className={`${classes.categoryText} ${
          active && classes.categoryTextSelected
        }`}
      >
        {` ${name} `}
      </Typography>
    </Box>
  );
};
