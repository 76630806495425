import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { AppBar, makeStyles, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import NavbarDesktop from "./components/NavbarDesktop";
import NavbarMobile from "./components/NavbarMobile";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "white",
  },
});

const Navbar = () => {
  const theme = useTheme();
  const matchesUplg = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();

  return (
    <Fragment>
      <Route
        path="/"
        render={(history) => {
          if (history.location.pathname === "/bienvenida") {
            return null;
          }
          return (
            <AppBar className={classes.appBar} position="sticky">
              {matchesUplg ? (
                <Grid container>
                  <NavbarDesktop history={history} />
                </Grid>
              ) : (
                <NavbarMobile />
              )}
            </AppBar>
          );
        }}
      />
    </Fragment>
  );
};

export default Navbar;
