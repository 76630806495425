import { methodologyTypes } from "../types/methodologyTypes";

export const initialState = {
  methodologyData: {},
  loadingMethodology: true,
};

export const methodologyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case methodologyTypes.GET_DATA:
      return {
        ...state,
        methodologyData: action.payload.data,
        loadingMethodology: false,
      };
    case methodologyTypes.GET_ERROR:
      return {
        ...state,
        loadingMethodology: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
