import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Group as GroupIcon,
  Book as Blog,
} from "@material-ui/icons";

import MovieIcon from "@material-ui/icons/Movie";
import WorkIcon from "@material-ui/icons/Work";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Logo from "../../../assets/img/logo-menu.svg";
import { OPPORTUNITIES_ROUTE, RouteNames } from "../../../routers/RouteNames";
import resetBlogState from "../../../Blog/functions/resetBlogState";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  logo: {
    padding: "1.1rem 3rem 1rem 3rem",
  },
  menuText: {
    color: "#472B8F",
    fontWeight: "bold",
  },
  list: {
    width: "12rem",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: "2.5rem",
    display: "block",
    color: "#464646",
  },
});

const NavbarMobile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { blog, videos } = useSelector((state) => state.FeatureFlags);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerItems = [
    {
      text: "Oportunidades",
      icon: <WorkIcon />,
      link: OPPORTUNITIES_ROUTE,
    },
    {
      text: "Nosotros",
      icon: <GroupIcon />,
      link: RouteNames.ABOUT_US,
    },
    {
      text: "Blog",
      icon: <Blog />,
      link: RouteNames.BLOG,
    },
    {
      text: "Videos",
      icon: <MovieIcon />,
      link: RouteNames.VIDEOS,
    },
  ];

  const socialItems = [
    {
      text: "LinkedIn",
      icon: <LinkedInIcon />,
      link: RouteNames.LINKEDIN,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={8}
          sm={10}
          alignItems="center"
          justify="flex-start"
        >
          <Link to={RouteNames.HOME}>
            <img src={Logo} alt="Logo Ripleytech" className={classes.logo} />
          </Link>
        </Grid>

        <Grid container item xs={2} sm={1} alignItems="center" justify="center">
          <Typography variant="body1" className={classes.menuText}>
            Menú
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={2}
          sm={1}
          alignItems="center"
          justify="flex-start"
        >
          <IconButton
            onClick={toggleDrawer}
            className={classes.menuIcon}
            edge="end"
            size="medium"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        variant="temporary"
        onClose={toggleDrawer}
        open={drawerOpen}
      >
        <List className={classes.list}>
          {drawerItems.map((prop) => {
            if (
              (prop.text === "Blog" && blog === false) ||
              (prop.text === "Videos" && videos === false)
            ) {
              return null;
            }
            return (
              <Link
                className={classes.link}
                to={prop.link}
                key={prop.text}
                onClick={resetBlogState(dispatch)}
              >
                <ListItem onClick={toggleDrawer} button>
                  <ListItemIcon className={classes.itemIcon}>
                    {prop.icon}
                  </ListItemIcon>
                  <ListItemText>{prop.text}</ListItemText>
                </ListItem>
              </Link>
            );
          })}
        </List>

        <Divider />

        <List className={classes.list}>
          {socialItems.map((prop) => (
            <a
              className={classes.link}
              href={prop.link}
              target="_blank"
              rel="noopener noreferrer"
              key={prop.text}
            >
              <ListItem onClick={toggleDrawer} button>
                <ListItemIcon className={classes.itemIcon}>
                  {prop.icon}
                </ListItemIcon>
                <ListItemText>{prop.text}</ListItemText>
              </ListItem>
            </a>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default NavbarMobile;
