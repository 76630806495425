import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { TagsReducer } from "../Blog/reduce/TagsReducer";
import thunk from "redux-thunk";
import ContentTypeReducer from "../Blog/reduce/ContentTypeReducer";
import { PostsReducer } from "../Blog/reduce/PostsReducer";
import { GOBReducer } from "../Home/reduce/reducerGOB";
import { FeatureFlagsReducer } from "../reducerIndex/reducer/FeatureFlagsReducer";
import { methodologyReducer } from "../AboutUs/reducers/methodologyReducer";
import { VideosReducer } from "../Video/reducer/VideosReducer";
import { PodcastPlaylistReducer } from "../Blog/reduce/PodcastPlaylistReducer";
import { WelcomeReducer } from "../Welcome/store/WelcomeReducer";
import { ReferenceReducer } from "../Opportunities/store/ReferenceReducer";
import { OpportunitiesReducer } from "../Opportunities/store/OpportuinitiesReducer";
import { QuestionsReducer } from "../Opportunities/store/QuestionsReducer";

const reducers = combineReducers({
  BlogTags: TagsReducer,
  ContentTypes: ContentTypeReducer,
  Posts: PostsReducer,
  GOB: GOBReducer,
  FeatureFlags: FeatureFlagsReducer,
  MethodologyReducer: methodologyReducer,
  Video: VideosReducer,
  PodcastPlaylist: PodcastPlaylistReducer,
  WelcomeSite: WelcomeReducer,
  OpportunitiesReferences: ReferenceReducer,
  Opportunities: OpportunitiesReducer,
  Questions: QuestionsReducer,
});

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
