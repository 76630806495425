import React, { useState, useEffect } from "react";
import axios from "axios";

import "../../../assets/css/footer.css";

import { Typography } from "@material-ui/core";

export const TextoFooter = () => {
  const [text, setText] = useState([]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/texto-footers`
        );
        const data = await res.data[0];
        if (mounted) {
          setText(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    return function cleanup() {
      mounted = false;
    }
  }, []);

  return (
    <>{<Typography variant="body1">{text.textoFooter}</Typography>}</>
  );
};

export default TextoFooter;
