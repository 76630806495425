import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../assets/css/footer.css";

// import { makeStyles } from '@material-ui/core/styles';

const SocialNetworksButtons = () => {
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/rrssbuttons`
        );
        const data = await res.data;
        if (mounted) {
          setIcon(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <>
      {icon.map((item, index) => (
        <a key={item._id} href={item.url}>
          {item.rrssLogo.map((logo) => (
            <img
              key={item.id}
              className="logoRrss"
              id={"rrss" + index}
              src={logo.url}
              alt={item.Alt}
            />
          ))}
        </a>
      ))}
    </>
  );
};

export default SocialNetworksButtons;
