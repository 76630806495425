import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";

import ReactPlayer from "react-player/youtube";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { AuthorVideo } from "../components/OutstadingVideoView/AuthorVideo";
import { Loading } from "../../UI/loading/Loading";

export const OutstadingVideo = () => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));
  const { OutstandingVideos } = useSelector((state) => state.Video);
  if (OutstandingVideos.length === 0) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ margin: "2rem 0rem 0rem 0rem" }}
      >
        <ReactPlayer
          width={match ? "80%" : "90%"}
          height={match ? "645px" : "32vh"}
          controls={true}
          url={`https://www.youtube.com/watch?v=${OutstandingVideos.url}`}
        />
        <AuthorVideo
          date={OutstandingVideos.createdAt}
          Title={OutstandingVideos.Title}
          author={OutstandingVideos.autore}
        />
      </Grid>
    </>
  );
};
