import axios from "axios";
import PostTypes from "../Types/PostTypes";

export const getPosts = () => async (dispatch) => {
  try {
    const { data: postsData } = await axios(
      `${process.env.REACT_APP_PUBLIC_URL}/articulos?_sort=fecha_publicacion:DESC`
    );
    const i = postsData.findIndex((art) => {
      return art.Destacado === true;
    });

    dispatch({
      type: PostTypes.GET_POSTS,
      payload: { data: postsData, indexDestacado: i },
    });
  } catch (error) {
    dispatch({
      type: PostTypes.ERROR_POSTS,
      payload: error,
    });
  }
};

export const setPostsPage = (page) => (dispatch) => {
  dispatch(getActionPage(page));
};

export const getActionPage = (page) => {
  return {
    type: PostTypes.SET_PAGE,
    payload: {
      postsPage: page,
    },
  };
};

export const setTotalPages = (pagesQuantity) => (dispatch) => {
  dispatch(getActionTotalPages(pagesQuantity));
};
export const getActionTotalPages = (pagesQuantity) => {
  return {
    type: PostTypes.SET_TOTAL_PAGES,
    payload: {
      totalPages: pagesQuantity,
    },
  };
};
