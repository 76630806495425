import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  postDescription: {
    textAlign: "left",
    color: "#464646",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    margin: " 0rem 0rem 0rem 0rem",
    cursor: "pointer",
    fontFamily: "Barlow",
  },
});

export const PostSummaryDescription = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      className={classes.postDescription}
      gutterBottom
    >
      {text}
    </Typography>
  );
};
