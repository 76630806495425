import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
const Styles = makeStyles({
  imgAvatar: {
    borderRadius: "45px",
  },
  textName: {
    color: "#EB3C90",
    fontFamily: "Barlow, Georgia",
  },
  textCargo: {
    color: "#464646",
    fontFamily: "Barlow, Georgia",
  },
  textEmail: {
    color: "#464646",
    fontFamily: "Barlow, Georgia",
    textDecoration: "underline",
  },
});
export const AvatarAuthor = ({ icono, nombre, cargo, email }) => {
  const classes = Styles();
  return (
    <>
      <Box width="88px" height="88px" mt={10}>
        <img
          width="100%"
          className={classes.imgAvatar}
          src={`${icono.url}`}
          alt="icono autor"
        />
      </Box>
      <Typography variant="body1" className={classes.textName}>
        {nombre}
      </Typography>
      <Typography variant="body2" className={classes.textCargo}>
        {cargo}
      </Typography>
      <Typography variant="body2" className={classes.textEmail}>
        {email}
      </Typography>
    </>
  );
};
