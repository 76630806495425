import { setPostsPage } from "../actions/PostActions";
import ContentTypeTypes from "../Types/ContentTypeTypes";
import { TagsTypes } from "../Types/TagsTypes";

const resetBlogState = (dispatch) => {
  return () => {
    dispatch({ type: TagsTypes.RESET_FILTER });
    dispatch({ type: ContentTypeTypes.RESET_CONTENT_TYPES });
    dispatch(setPostsPage(1));
  };
};

export default resetBlogState;
