import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

export const TeamProject = ({
  logo,
  largo,
  color,
  side = false,
  sideMobil,
  name,
}) => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));

  return (
    <>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        wrap={sideMobil ? "nowrap" : "wrap"}
        style={{
          margin: side ? "1rem 0rem 1rem 0rem" : "1rem",
          cursor: "pointer",
          background: color,
          width: side ? "93%" : "auto",
        }}
        xs={side ? 12 : 12 / largo}
        sm={side ? 12 : 12 / largo}
        md={side ? 12 : 12 / largo}
        lg={side ? 12 : 12 / largo}
        xl={side ? 12 : 12 / largo}
      >
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Link
            to={`/Videos?target=${name} `}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              padding: match
                ? "2rem 4rem 2rem 4rem"
                : "0.5rem 1rem 0.5rem 1rem",
            }}
          >
            <img
              src={`${logo[0].url}`}
              width={match ? "120px" : "45px"}
              alt="imgEquipo"
            />
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
