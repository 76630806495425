import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RouteNames } from "../../../routers/RouteNames";
import { addContentType } from "../../actions/ContentTypeActions";
import { setPostsPage } from "../../actions/PostActions";
import resetBlogState from "../../functions/resetBlogState";
import ContentTypeTypes from "../../Types/ContentTypeTypes";
import { TagsTypes } from "../../Types/TagsTypes";

const styles = makeStyles({
  textTitle: {
    color: "inherit",
  },
  breadStyle: {
    width: "100%",
    padding: "2rem 0rem 1rem 0rem",
  },
});

export const BlogBreadcrumbs = ({
  title,
  ShowCategory = false,
  tipoContenido,
  contentTypeId = null,
}) => {
  const { selectedTags } = useSelector((state) => state.BlogTags);
  const classes = styles();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={`•`}
        className={classes.breadStyle}
      >
        <Link
          color="inherit"
          to={RouteNames.BLOG}
          style={{ color: "inherit" }}
          onClick={resetBlogState(dispatch)}
        >
          Blog
        </Link>
        {selectedTags.length !== 0 && ShowCategory && (
          <Link
            to={RouteNames.BLOG}
            color="inherit"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Tags:{" "}
            {selectedTags.map((cat, index) => {
              if (selectedTags.length - 1 === index) {
                return cat;
              }
              return cat + ", ";
            })}
          </Link>
        )}
        {tipoContenido && (
          <Link
            color="inherit"
            to={RouteNames.BLOG}
            style={{ color: "inherit" }}
            onClick={() => {
              dispatch({ type: TagsTypes.RESET_FILTER });
              dispatch({ type: ContentTypeTypes.RESET_CONTENT_TYPES });
              dispatch(setPostsPage(1));
              dispatch(addContentType(contentTypeId));
            }}
          >
            {tipoContenido}
          </Link>
        )}
        {title && (
          <Typography
            variant="body2"
            align="left"
            className={classes.textTitle}
          >
            {title}
          </Typography>
        )}
      </Breadcrumbs>
    </Fragment>
  );
};
