import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  makeStyles,
  Grid,
  useMediaQuery,
  Breadcrumbs,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { PreviewVideo } from "../components/ColumnCategoryView/PreviewVideo";
import { Link } from "react-router-dom";
const Styles = makeStyles({
  BoxCategory: {
    background: "#D5D5D5",
    borderRadius: "4px",
  },
  textCategory: {
    fontWeight: "bold",
    fontFamily: "barlow",
    textAlign: "center",
    padding: "0rem 1rem 0rem 1rem",
  },
});

export const ColumnCategory = ({
  NameCategory,
  Videos = [],
  bread = false,
}) => {
  const Theme = useTheme();
  const match = useMediaQuery(Theme.breakpoints.up("md"));
  const classes = Styles();

  return (
    <>
      <Grid
        container
        justify="center"
        style={{ padding: "1rem 0rem 1rem 0rem" }}
      >
        <Grid container style={{ width: match ? "80%" : "90%" }}>
          {bread && (
            <Breadcrumbs
              separator={"•"}
              aria-label="breadcrumb"
              style={{ margin: "2rem 0rem 2rem 0rem" }}
            >
              <Link
                color="inherit"
                to="/Videos"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Videos
              </Link>

              <Typography color="textPrimary">{NameCategory}</Typography>
            </Breadcrumbs>
          )}

          <Grid container justify="space-between">
            <Box className={classes.BoxCategory} p={1}>
              <Typography variant="body1" className={classes.textCategory}>
                {NameCategory}
              </Typography>
            </Box>
            {document.getElementById(NameCategory) &&
              (Videos.length > 3 || (!match && Videos.length > 1)) && (
                <>
                  <Box
                    className={classes.BoxCategory}
                    p={1}
                    onClick={() => {
                      document.getElementById(NameCategory).scrollTo({
                        left:
                          document.getElementById(NameCategory).scrollLeft +
                          300,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.textCategory}
                    >
                      {">"}
                    </Typography>
                  </Box>
                </>
              )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            id={NameCategory}
            wrap="nowrap"
            direction="row"
            spacing={0}
            style={{
              display: "-webkit-box",
              overflowX:
                Videos.length > 3 || (!match && Videos.length > 1)
                  ? "scroll"
                  : null,
              width: "100%",
            }}
          >
            {Videos.map((clip, i) => {
              return <PreviewVideo {...clip} key={i} />;
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
